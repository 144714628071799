import * as React from 'react';
import { PageProps } from 'gatsby';
import { PageLayout as Layout } from '../components/Layouts/PageLayout/PageLayout';
import { Seo } from '../components/Seo/Seo';

import { PageTitle } from '../components/ui/pageTitle/pageTitle';

import PageContentHandler from '../components/PageContentHandler';
import pageHomeData from './data/pageHome.json';
import pageHomePreviewData from './data/pageHomePreview.json';

// markup
const IndexPage: React.FC<PageProps> = ({ location }) => {
  if (!location.hostname) return null;

  const pageData =
    location.hostname === 'gatsby.node.tvg.la' ||
    location.hostname === 'localhost'
      ? pageHomePreviewData[0]
      : pageHomeData[0];

  if (!pageData) return null;

  return (
    <Layout className="index bg bg-index">
      <Seo />
      <PageTitle title="What to Watch" />
      <PageContentHandler pageData={pageData} />
    </Layout>
  );
};

export default IndexPage;
